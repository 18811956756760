<template>
    <div class="reply">
        <div class="cursor" style="color:#CD0000;" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i>返回</div>
        <div class="content">
            <div class="line_01">答复详情</div>
            <div>
                <div>
                    <div style="margin:15px 0px;">详情信息</div>
                    <div>
                        <table border="1" style="border-collapse:collapse;border-color:#E7E7E7;width:100%;">
                            <tr>
                                <td class="left">标题</td>
                                <td class="right">{{info.title}}</td>
                            </tr>
                            <tr>
                                <td class="left">留言时间</td>
                                <td class="right">{{$commit.formatter(info.created_at)}}</td>
                            </tr>
                            <tr>
                                <td class="left">留言人</td>
                                <td class="right">{{info.user.nickname}}</td>
                            </tr>
                            <tr>
                                <td class="left">留言内容</td>
                                <td class="right">{{info.content}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="margin-top:30px;">
                    <div style="margin:15px 0px;">受理回复</div>
                    <div>
                        <table border="1" style="border-collapse:collapse;border-color:#E7E7E7;width:100%;">
                            <tr>
                                <td class="left">回复内容</td>
                                <td class="right">{{info.replay}}</td>
                            </tr>
                            <tr>
                                <td class="left">答复部门</td>
                                <td class="right">{{info.replayer}}</td>
                            </tr>
                            <tr>
                                <td class="left">答复时间</td>
                                <td class="right">{{$commit.formatter(info.verify_at)}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {feedback_detail} from '@/api/feedback.js'
export default {
    data() {
        return {
            info:{}
        };
    },
    created() {
        this.getInfo()
    },
    mounted() {

    },
    methods: {
        getInfo(){
            feedback_detail(this.$route.query.id).then(res=>{
                if(res.code==200){
                    this.info = res.data
                }
            })
        },
    }
};
</script>

<style scoped lang="scss">
    .reply{
        margin: 20px auto;
        .content{
            margin-top: 40px;
            .line_01{ 
                width:100px;
                padding: 0 20px 0;
                margin:0px auto 30px;  
                line-height: 2px;  
                color: #CD0000;
                font-weight: bold;
                border-left: 80px solid #CD0000;  
                border-right: 80px solid #CD0000;  
                text-align: center;  
            }
            ::v-deep .el-table th.el-table__cell.is-leaf{
                color: #CD0000;
            }
            .left{
                background: #FCF7F7;
                color: #CD0000;
                width:138px;
                text-align: center;
            }
            .right{
                padding:10px 22px;
                max-width: 100%;
            }
        }
    }
</style>
